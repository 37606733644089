/* Audio Player Styles
================================================== */

/* Default / Desktop / Firefox */
.album-player {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 40px 0 40px;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-items: flex-start;
  max-width: var(--max-width);
  margin: auto;
  > div {
    margin: 20px 0;
  }
}

audio {
  margin: 0;
  width: 100%;
}

.player-wrap {
  flex: 1 1 400px;
  max-width: 700px;
  border-radius: 5px;
  width: 100%;
}
#plwrap {

  ul {
    list-style: none;
    padding: 0;
    li {
      border-top: solid 1px var(--fg-alt);

    }
    li:first-child {
      border-radius: 5px 5px 0 0 ;
    }
    li:last-child {
      border-radius: 0 0 5px 5px;
      border-bottom: solid 1px var(--fg-alt);
    }
  }
}

#audiowrap {
  background-color: var(--bg);
  margin: 0 auto 0 auto;
}

#plwrap {
  margin: 0 auto;
}

#tracks {
  flex: 1 0 200px;
  min-height: 65px;
  position: relative;
  text-align: center;
  text-decoration: none;
  padding-top: 20px;
}


#nowPlay {
  display: flex;
  width: 100%;
  height: 80px;
  flex-wrap: unset;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--bg);

}

#npTitle {
  flex: 1 1 100%;
  margin: 0;
  padding: 21px;

}

#npAction {
  display: none;
  margin: 0;
  padding: 21px;
  font-size: 12px;
}

#plList {
  margin: 0;
}

#plList li {
  background-color: var(--bg);
  cursor: pointer;
  margin: 0;
  padding: 21px 0;
  border-left: 1px solid var(--fg-alt);
  border-right: 1px solid var(--fg-alt);
  border-top: 0;
  transition: all 400ms ease-in-out;
}

#plList li:hover {
  background-color: var(--accent1);
  color: var(--bg);
  transition: all 400ms ease-in-out;
}

.plItem {
  position: relative;
}

.plTitle {
  left: 40px;
  overflow: hidden;
  position: absolute;
  right: 65px;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  font-weight: bold;
  padding-right: 10px;
}

.plNum {
  padding-left: 20px;
  width: 20px;
}

.plLength {
  padding-left: 21px;
  position: absolute;
  right: 21px;
  top: 0;
}

.plSel {
  background-color: var(--fg-alt)!important;
  cursor: pointer!important;
}
.plSel:hover {
  background-color: var(--accent1)!important;
}


a[id^="btn"] {
  font-size: 1.5em;
  color: var(--fg);
  cursor: pointer;
  margin: 0;
  padding: 0 27px 0 21px;
  text-decoration: none;
}


a[id^="btn"]::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.coverart {
  flex: 1 1 400px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  #author {
    margin: 40px 0 0 0;
    flex: 1 1 auto;
    text-align: center;
  }
}
.coverartwrapper {

  width: 70%;
  padding-top: 70%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}
 .coverartwrapped {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  padding-bottom: 0;
  background-size: cover;
  
}
.spinnit {

  -webkit-animation: infiniteRotate 2s linear infinite; /* Safari */
  -moz-animation: infiniteRotate 2s linear infinite;
  animation: infiniteRotate 2s linear infinite;
}

.paused {
  animation-play-state: paused;
}

.track-picker {
  display: flex;
  flex-wrap: wrap;
  margin:0;
  padding:0;
  background-color: var(--accent1);
  margin: 0;
  padding: 0;
  min-height: 50vh;
  text-align: left;
  .button {
    background-color: var(--accent1);
    color: var(--bg);
  }
  .button:hover {
    background-color: var(--accent2);
    
  }
  > div {
    flex: 1 1 auto;
    width: 50%;
    min-width: 280px;
    margin: 0;
    padding: 0;
    background-color: var(--bg);
    -webkit-box-shadow: inset 0px 23px 25px -25px var(--border-alt);
    -moz-box-shadow: inset 0px 23px 25px -25px var(--border-alt);
    box-shadow: inset 0px 23px 25px -25px var(--border-alt);
    
    > h2, p {
      text-align: left;
      margin: 20px 40px;
    }
    p:last-child {
      margin-bottom: 40px;
    }
  }

  .full-image {
    width: 50%;
    min-width: 280px;
    min-height: 50vh;
    background-color: transparent;
    background-size: cover;
    background-position: center;
  }

}

.track-picker:nth-child(even) {
  flex-direction: row-reverse;
  background-color: var(--accent2);
  text-align: right;
  .button {
    background-color: var(--accent2);

  }
  .button:hover{
    background-color: var(--accent1);

  }
  > div {
    > h2, a, p {
      text-align: right;

    }
  }
}

#fixedPlayer {
  background-color: var(--bg);
  margin: 0;
  padding:0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  -webkit-box-shadow: 0px 3px 35px 2px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 3px 35px 2px rgba(0,0,0,0.3);
  box-shadow: 0px 3px 35px 2px rgba(0,0,0,0.3);
  -webkit-transition: all 400ms ease-in;
  -moz-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
}
.showplayer {
  height: 120px;

}
.hideplayer {
  height: 0;
}
@media only screen and (max-width: 559px) {
  .track-picker {
    background-size: 100%;
    .full-image {
      min-height: 100vw;
    }

  }
  #nowPlay {

    align-items: baseline;
    padding-bottom: 20px;
  }
  #tracks {
    flex: 1 0 100px;
    width: 100px;
    position: relative;
    text-align: center;
    text-decoration: none;

  } 
  a[id^="btn"] {
    font-size: 1em;
    color: var(--fg);
    cursor: pointer;
    margin: 0;
    padding: 0 0 0 20px;
    text-decoration: none;
  }
  #npTitle{

    font-size: 0.8em;

    text-decoration: none;
    margin: 0;
    padding: 0 0 0 20px;
  }
}