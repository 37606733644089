/// Removes a specific item from a list.
/// @author Hugo Giraudel
/// @param {list} $list List.
/// @param {integer} $index Index.
/// @return {list} Updated list.
@function remove-nth($list, $index) {

	$result: null;

	@if type-of($index) != number {
		@warn "$index: #{quote($index)} is not a number for `remove-nth`.";
	}
	@else if $index == 0 {
		@warn "List index 0 must be a non-zero integer for `remove-nth`.";
	}
	@else if abs($index) > length($list) {
		@warn "List index is #{$index} but list is only #{length($list)} item long for `remove-nth`.";
	}
	@else {

		$result: ();
		$index: if($index < 0, length($list) + $index + 1, $index);

		@for $i from 1 through length($list) {

			@if $i != $index {
				$result: append($result, nth($list, $i));
			}

		}

	}

	@return $result;

}

/// Gets a value from a map.
/// @author Hugo Giraudel
/// @param {map} $map Map.
/// @param {string} $keys Key(s).
/// @return {string} Value.
@function val($map, $keys...) {

	@if nth($keys, 1) == null {
		$keys: remove-nth($keys, 1);
	}

	@each $key in $keys {
		$map: map-get($map, $key);
	}

	@return $map;

}

/// Gets a font value.
/// @param {string} $keys Key(s).
/// @return {string} Value.
@function _font($keys...) {
	@return val($font, $keys...);
}

// /// Gets a palette value.
// /// @param {string} $keys Key(s).
// /// @return {string} Value.
// @function _palette($keys...) {
// 	@return val($palette, $keys...);
// }



@font-face {
  font-family: 'Jetbrain-regular';
  font-style: normal;
  font-weight: 400;
  src: local('Jetbrain Regular'), local('Jetbrain-Regular'), url(/assets/fonts/jetBrainsMono/JetBrainsMono_Regular.ttf) format('truetype'), url(/assets/fonts/jetBrainsMono/JetBrainsMono-Regular.woff) format('woff'), url(/assets/fonts/jetBrainsMono/JetBrainsMono-Regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Jetbrain-regular';
  font-style: italic;
  font-weight: 400;
  src: local('Jetbrain Regular'), local('Jetbrain-Regular'), url(/assets/fonts/jetBrainsMono/JetBrainsMono_Italic.ttf) format('truetype'), url(/assets/fonts/jetBrainsMono/JetBrainsMono-Italic.woff) format('woff'), url(/assets/fonts/jetBrainsMono/JetBrainsMono-Italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Jetbrain-Extrabold';
  font-style: normal;
  font-weight: 700;
  src: local('Jetbrain ExtraBold'), local('Jetbrain-ExtraBold'), url(/assets/fonts/jetBrainsMono/JetBrainsMono_ExtraBold.ttf) format('truetype'), url(/assets/fonts/jetBrainsMono/JetBrainsMono-ExtraBold.woff) format('woff'), url(/assets/fonts/jetBrainsMono/JetBrainsMono-ExtraBold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Jetbrain-Extrabold';
  font-style: italic;
  font-weight: 700;
  src: local('Jetbrain ExtraBold'), local('Jetbrain-ExtraBold'), url(/assets/fonts/JetBrainsMono_ExtraBold_Italic.ttf) format('truetype'), url(/assets/fonts/jetBrainsMono/JetBrainsMono-ExtraBold-Italic.woff) format('woff'), url(/assets/fonts/jetBrainsMono/JetBrainsMono-ExtraBold-Italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url(/assets/fonts/poppins/Poppins-Light.ttf) format('truetype'), url(/assets/fonts/poppins/popin-light.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url(/assets/fonts/poppins/Poppins-LightItalic.ttf) format('truetype'), url(/assets/fonts/poppins/popin-light-italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(/assets/fonts/poppins/Poppins-Medium.ttf) format('truetype'), url(/assets/fonts/poppins/popin-medium.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(/assets/fonts/poppins/Poppins-MediumItalic.ttf) format('truetype'), url(/assets/fonts/poppins/popin-medium-italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



$font: (
  title:         ('Jetbrain-Extrabold', 'Lucida Console', Monaco, monospace),
  family:        ('Poppins', 'Arial', 'Helvetica', sans-serif),
  family-fixed:  ('Jetbrain-Regular', 'Lucida Console', Monaco, monospace),
  weight:       400,
  weight-bold:  700,
  kerning:      0em,
  kerning-alt:  0.125em
);
:root {
  --border-radius: 3px;
  --max-width: 1000px;
  --anim-speed: 200ms;

}

// // Palette.
// $palette: (

//   bg:         #0C0F0A, 
//   bg-alt:     rgba(12, 15, 10, 0.5), 
//   fg:         #FFFFFF,
//   fg-alt:     rgba(255, 255, 255, 0.5),
//   border:     #000000,
//   border-alt: rgba(0, 0, 0, 0.75),
//   accent1:    #41EAD4,
//   accent1-alt:rgba(65, 234, 212, 0.75),
//   accent2:    #FF206E,
//   accent2-alt:rgba(255, 32, 110, 0.75),

// );