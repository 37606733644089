//* Cloudcannon *//

.editor-link {
  display: none;
  margin-top: 0;
  padding-top: 30px;
 .btn {
   border: 0;
   border-radius: var(--border-radius);
   width: 100%;
   max-width: 500px;
   box-sizing: border-box;
   text-decoration: none;
   padding: 10px 15px;
   margin: 0;
   font-size: 18px;
   cursor: pointer;
   background-color: #f7e064;
   color: #333;
   box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
   &:hover {
     background-color: #f4d525;
     color: #333;
   }
 }
}

.cms-editor-active .editor-link {
  display: block;
}

.noJSalbum {
  display: none;
}